<template>
    <div id="similarCase-mobile">
        <div v-if="typeof pageTotal == 'number' && pageTotal != 0">
            <div
                class="similarCase-mobile-li"
                v-for="item in docs"
                :key="item.id"
                @click="linkToDetail(item.id)"
            >
                <div class="title">{{ item.caseTitle }}</div>
                <div class="label">
                    <span v-for="(data, index) in item.labelName.split(',')" :key="index">{{
                        data
                    }}</span>
                </div>
                <div
                    class="cont result-list"
                    v-if="item.highlightFields || item.highlightFields.length != 0"
                >
                    <div>
                        <div class="left"></div>
                        <div class="right"></div>
                    </div>

                    <div class="case-footer">
                        <div>{{ item.court }}</div>
                        <div>{{ item.caseType }}</div>
                        <div>{{ item.procedureId }}</div>
                        <div>{{ item.instrumentTypeId }}</div>
                        <div>{{ item.judgementDate.substr(0, 10) }}</div>
                        <div>{{ item.caseNo }}</div>
                    </div>
                </div>
            </div>
        </div>

        <div v-else-if="typeof pageTotal != 'number'" class="notFont">
            <img src="../../assets/img/mobileTerminal/loading.gif" alt="" />
        </div>

        <div v-else class="notFont">
            <img src="../../assets/img/mobileTerminal/notFont.png" alt="" />
            <span>暂无数据</span>
        </div>
    </div>
</template>

<script>
    import * as Server from '@/api/server.js'

    let caseType = {
        '1': '刑事',
        '2': '民事',
        '3': '行政',
        '4': '赔偿/救助',
        '5': '执行/保全',
        '6': '协助/制裁',
    }

    let procedureId = {
        '1': '一审',
        '2': '二审',
        '3': '审判监督',
        '4': '没收违法所得',
        '5': '没收违法所得',
        '6': '强制医疗',
        '7': '刑罚与执行变更',
        '8': '其他',
        '9': '第三人撤销之诉',
        '10': '特别程序',
        '11': '催告案件',
        '12': '督促案件',
        '13': '破产/强制清算',
        '14': '非诉审查',
        '15': '执行实施',
        '16': '执行审查',
        '17': '其他执行',
        '18': '财产保全',
        '19': '行为保全',
        '20': '行为保全复议',
        '21': '证据保全',
        '22': '行政赔偿',
        '23': '司法赔偿',
        '24': '其他赔偿',
        '25': '司法救助',
        '26': '其他司法救助',
        '27': '认可与执行申请审查',
        '28': '送达文书',
        '29': '调查取证',
        '30': '被判刑人移管',
        '31': '罪赃移交',
        '32': '引渡',
        '33': '司法制裁审查',
        '34': '司法制裁复议',
        '35': '管辖',
    }

    //文书类型
    let instrumentTypeId = {
        '1': '判决书',
        '2': '裁定书',
        '3': '调解书',
        '4': '决定书',
        '5': '通知书',
        '9': '支付令',
        '10': '执行令',
        '6': '批复',
        '7': '答复',
        '8': '函',
        '11': '其他',
    }
    export default {
        name: 'SimilarCase',
        data() {
            return {
                pageTotal: null,
                docs: [],
                visible: false,
                detail: {},
                textListData: [],
            }
        },
        mounted() {
            this.getList()
        },
        methods: {
            /**
             * 根据id对应name
             */
            getNameFromConstDict: function() {
                for (let i = 0; i < this.docs.length; i++) {
                    // 案件类型caseType
                    this.docs[i].caseType = caseType[this.docs[i].caseType]
                    // 审判程序procedureId
                    this.docs[i].procedureId = procedureId[this.docs[i].procedureId]
                    // 文书类型instrumentTypeId
                    this.docs[i].instrumentTypeId = instrumentTypeId[this.docs[i].instrumentTypeId]
                }
            },
            async getList() {
                //请求数据
                let data = {
                    method: 'GET',
                    url:
                        'themis-terminal-api/b/q/caseMaintenance/getCaseIdDetail/' +
                        this.$route.query.moduleId +
                        '/' +
                        this.$route.query.resultId,
                    config: {},
                    loading: false,
                }
                let dataSource = await Server.Axios(data)
                if (dataSource.data.returnCode == 500) {
                    this.$message.error('服务出错，请稍后重试')
                    return
                }
                if (dataSource.data.returnCode == 200) {
                    this.pageTotal = dataSource.data.returnData.length
                    if (dataSource.data.returnData.length > 0) {
                        dataSource.data.returnData.forEach((el) => {
                            el.highlightFields = []
                            if (el.courtHeld) {
                                el.highlightFields.push({
                                    highlightField: '本院认为',
                                    text: el.courtHeld,
                                })
                            }
                            if (el.courtHeld) {
                                el.highlightFields.push({
                                    highlightField: '本院查明',
                                    text: el.ascertain,
                                })
                            }
                        })
                        this.docs = dataSource.data.returnData
                        this.getNameFromConstDict()
                    }
                }
            },
            async linkToDetail(id) {
                window.console.log(id)
                this.$router.push({
                    name: 'MobileDetail',
                    query: {
                        documentId: id,
                    },
                })
            },
        },
    }
</script>

<style lang="scss" type="text/scss">
    #similarCase-mobile {
        .similarCase-mobile-li {
            position: relative;
            padding: 0.3rem;
            .title {
                font-size: 0.3rem;
                font-weight: bold;
                color: #333333;
                line-height: 24px;
                font-size: 16px;
            }

            .label {
                display: flex;
                flex-wrap: wrap;
                margin-top: 0.2rem;
                span {
                    background-color: #1cd4bd;
                    color: #fff;
                    margin: 0 0.2rem 0.1rem 0;
                    padding: 0.09rem 0.15rem;
                    font-size: 12px;
                    border-radius: 3px;
                }
            }
        }

        .similarCase-mobile-li:active {
            background-color: #eee;
        }

        .case-footer {
            display: flex !important;
            flex-wrap: wrap;
            justify-content: flex-start;
            padding: 0;
            margin-top: 10px;
            div {
                padding-right: 0.3rem;
                position: relative;
            }
            //div:after{
            //  content:"";
            //  position: absolute;
            //  width: 1px;
            //  height: 14px;
            //  background-color: #ccc;
            //  transform: scaleX(0.7);
            //  right: .14rem;
            //  top: calc(50% - 7px);
            //}
        }
    }
    .similarCase-mobile-li:after {
        position: absolute;
        left: 0;
        bottom: 0;
        content: ' ';
        width: 100%;
        height: 0;
        border-bottom: 1px solid #ccc;
        transform-origin: 0 0;
        transform: scaleY(0.5);
    }
    .notFont {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 200px;
        margin: 50px auto 0;
        img {
            width: 200px;
            height: 200px;
        }
    }
</style>
