<template>
    <div class="litigationGuidelines-content">
        <div class="docs" v-if="!loading">
            <div id="docs" ref="resume">
                <div v-html="htmlStr" class="docs-div"></div>
            </div>
        </div>
        <div v-else class="notFont">
            <img src="../../assets/img/mobileTerminal/loading.gif" alt="" />
        </div>
    </div>
</template>

<script>
    import * as Server from '@/api/server.js'

    export default {
        name: 'LitigationGuidelines',
        data() {
            return {
                htmlStr: '',
                docsArr: [],
                downWordUrl: '',
                type: this.$route.query.type,
                h1Str: '',
                loading: true,
            }
        },
        mounted() {
            this.getLitigationGuidelines()
        },
        created() {
            this.moduleId = this.$route.query.moduleId
        },
        methods: {
            //生成意见书
            async getLitigationGuidelines() {
                //请求数据
                let data = {
                    method: 'GET',
                    url:
                        'themis-terminal-api/b/q/litigationGuidelines/' +
                        this.$route.query.moduleId,
                    config: {},
                    loading: true,
                }
                let dataSource = await Server.Axios(data)

                this.loading = false
                if (dataSource.data.returnCode == 500) {
                    this.$message.error('服务出错，请稍后重试')
                    return
                }
                if (dataSource.data.returnCode == 200) {
                    this.htmlStr = dataSource.data.returnData.content

                    this.$emit('changeLitigationGuidelines', !!dataSource.data.returnData.content)
                } else {
                    this.htmlStr = '<div class="noFound">' + dataSource.data.returnInfo + '</div>'
                }
            },
        },
    }
</script>

<style lang="scss" type="text/scss">
    .litigationGuidelines-content {
        h1 {
            font-size: 18px;
            font-weight: bold;
            color: #358cf3;
            height: 1.09rem;
            line-height: 1.09rem;
            margin: 0;
            background-color: rgba(234, 243, 254);
            text-align: left;
            padding-left: 0.3rem;
        }

        .docs-div {
            background: #fff;
            color: #333;
            font-size: 14px;
            padding: 0.3rem 0.3rem.40rem;
            //text-indent: .32rem;
            img {
                display: block;
                width: 100%;
            }

            h2 {
                font-size: 16px;
                margin: 30px 0 5px;
                font-weight: bold;
                text-indent: 0;
            }

            h3 {
                text-align: center;
                font-size: 20px;
                margin-top: 0.1rem;
                font-weight: bold;
                text-indent: 0;
            }

            h4 {
                text-align: right;
                font-size: 18px;
                margin-top: 0.1rem;
                text-indent: 0;
            }

            div {
                margin-bottom: 0;
                text-align: justify;
                font-size: 14px;
                margin-bottom: 0.1rem;
                line-height: 26px;
            }

            p {
                //text-indent: .32rem;
                margin-bottom: 0;
                text-align: justify;
            }
        }

        table {
            border-top: 1px solid #ebebeb;
            border-left: 1px solid #ebebeb;

            th {
                border-right: 1px solid #ebebeb;
                border-bottom: 1px solid #ebebeb;
                text-indent: 0;
                text-align: left;
                padding: 0 0.1rem;

                &:first-child {
                    width: 2rem;
                }

                &:last-child {
                    width: 2rem;
                }
            }

            td {
                border-right: 1px solid #ebebeb;
                border-bottom: 1px solid #ebebeb;
                text-indent: 0;
                text-align: left;
                padding: 0 0.1rem;
            }
        }
    }

    .noFound {
        font-size: 0.16rem;
        margin-top: 0.2rem;
    }
</style>
